

































import { Component, Vue, Ref, Watch, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import StudentInformationTimeline from '@/components/organisms/v3/StudentInformationTimeline.vue'
import CheckboxWithLabel from '@/components/molecules/CheckboxWithLabel.vue'
import RangeDatePicker from '@/components/molecules/RangeDatePicker.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import Conditions from '@/components/organisms/Conditions.vue'
import moment from 'moment'
import LocalMoment from '@/components/atoms/LocalMoment.vue'
import TabBase from '@/components/atoms/v3/TabBase.vue'
import VictoryBranch from '@/mixins/v3/VictoryBranch'
import Timelines from '@/mixins/v3/Timelines'
import { Condition } from '@/mixins/v3/Timelines'

@Component({
  components: {
    SidebarSwitcher,
    StudentInformationTimeline,
    CheckboxWithLabel,
    RangeDatePicker,
    ButtonBase,
    Conditions,
    TabBase,
  },
})
export default class Timeline extends Mixins(LocalMoment, VictoryBranch, Timelines) {
  private learningTypeDatas: object[] = [
    { label: '通常', value: 'AI' },
    { label: 'カスタム', value: 'CUSTOM' },
    { label: '入試', value: 'NY' },
    { label: '補助', value: 'HJ' },
    { label: '間違', value: 'MN' },
    { label: 'リトライ', value: 'RETRY' },
    { label: '類題', value: 'SIMILAR' },
  ]

  private subjectDatas: object[] = [
    { label: '算数/数学', value: 'su' },
    { label: '英語', value: 'ei' },
    { label: '国語', value: 'ko' },
    { label: '社会', value: 'sh' },
    { label: '理科', value: 'ri' },
  ]

  private learningMaterialDatas: object[] = [
    // { label: '確認テスト', value: 'KK' },
    { label: '導入動画', value: 'EZ' },
    { label: '理解度テスト', value: 'RK' },
    { label: '演習', value: 'EN' },
    { label: '宿題', value: 'SY' },
    { label: '動画（補助教材）', value: 'HD' },
    { label: '練習（補助教材）', value: 'HR' },
    { label: 'テスト（補助教材）', value: 'HT' },
    { label: '間違い直し', value: 'MN' },
    { label: 'ブックマーク', value: 'BOOKMARK' },
    { label: 'チャレンジ', value: 'CHALLENGE' },

  ]

  @Ref() rangeDatePicker!: RangeDatePicker

  private created() {
    this.keyCondition = 'timelineConditions'
  }

  private async mounted(): Promise<void> {
    if (this.isVictoryBranch) {
      this.learningMaterialDatas.push(
        { label: '【C】動画', value: 'VIDEO' },
        { label: '【C】教材', value: 'PR' },
        { label: '【C】漢字', value: 'KJ' }
      )
    }
    await this.init(this.loadDatas, this.restoreConditions, this.select)
  }

  private async loadDatas() {
    const params = this.conditions.reduce((accumlator: any, condition: any): any => {
      accumlator[condition.key] = condition.value
      return accumlator
    }, {})

    // 期間開始に最終レコードの学習開始日時を設定
    if (this.lastData.startedAt) params.startedAtLteq = this.lastData.startedAt

    // TODO: 生徒アイコンは未定、無限スクロール対応は後回し
    await Vue.prototype.$http.httpWithToken
      .post('/v3/timelines', { q: params, lastData: this.lastData })
      .then((res: any) => {
        const add_timelines: [] = res.data.timelines.map((timeline: any) => {
          return {
            id: timeline.studentCode,
            name: timeline.nickname,
            icon: '',
            school: timeline.schoolName,
            grade: timeline.gradeName,
            date: timeline.studyDate,
            time: timeline.studyTime,
            type: timeline.studyType,
            item_text:
              timeline.isVictoryHistory && timeline.lessonName ? '【 V 】' + timeline.lessonName : timeline.lessonName,
            material: timeline.lessonType,
            study_time: timeline.duration,
            study_count: timeline.completeCount,
            result: { all: timeline.questionCount, correct: timeline.correctCount },
            learningFlag: timeline.learningFlag,
            resultDrillId: timeline.resultDrillId,
            resultDrillVersion: timeline.resultDrillVersion,
            isModeDefault: timeline.isModeDefault,
            isPause: timeline.isPause,
            learningType: timeline?.learningType
          }
        })
        this.timelineData.push(...add_timelines)
        this.lastData = res.data.meta.lastData
        // 最終行の場合、監視終了
        if (!add_timelines.length) this.observer.disconnect()
      })
  }

  private clear() {
    this.rangeDatePicker.startDatePickerBase.selectedDate = this.beforeDays(14).toDate()
    this.rangeDatePicker.endDatePickerBase.selectedDate = null
    this.learningTypeDatas.forEach((data: any) => {
      data.checked = false
    })
    this.subjectDatas.forEach((data: any) => {
      data.checked = false
    })
    this.learningMaterialDatas.forEach((data: any) => {
      data.checked = false
    })
  }

  private select() {
    const conditionsDatas = []

    // 期間開始
    const startDate = this.rangeDatePicker.startDatePickerBase.selectedDate
    if (startDate) {
      conditionsDatas.push({
        label: '期間開始',
        name: moment(startDate).format('YYYY/MM/DD'),
        key: 'startedAtGteq',
        value: moment(startDate).startOf('day').format('YYYY/MM/DD HH:mm:ssZ'),
      })
    }

    // 期間終了
    const endDate = this.rangeDatePicker.endDatePickerBase.selectedDate
    if (endDate) {
      conditionsDatas.push({
        label: '期間終了',
        name: moment(endDate).format('YYYY/MM/DD'),
        key: 'startedAtLteq',
        value: moment(endDate).endOf('day').format('YYYY/MM/DD HH:mm:ssZ'),
      })
    }

    // 学習種別
    this.addCheckBoxConditionData(conditionsDatas, this.learningTypeDatas, '学習種別', 'classModes')

    // 教科
    this.addCheckBoxConditionData(conditionsDatas, this.subjectDatas, '教科', 'subjects')

    // 学習教材
    this.addCheckBoxConditionData(conditionsDatas, this.learningMaterialDatas, '学習教材', 'classCategories')

    this.conditions = conditionsDatas
    this.setTimelineCookie()
    this.menuClose()
  }

  private addCheckBoxConditionData(conditionsDatas: any, checkBoxDatas: any, label: string, key: string) {
    const selectedDatas = checkBoxDatas.filter((data: any) => data.checked)
    if (selectedDatas.length > 0) {
      const valueData = selectedDatas.map((data: any) => {
        if (data.value === 'VIDEO') {
          return ['SM', 'BK', 'MT']
        }
        return data.value
      })
      conditionsDatas.push({
        label: label,
        name: selectedDatas.map((data: any) => data.label).join('、'),
        key: key,
        value: valueData.flat(),
      })
    }
  }

  private removeConditions(label: string) {
    if (label === '期間開始') this.rangeDatePicker.startDatePickerBase.selectedDate = null
    if (label === '期間終了') this.rangeDatePicker.endDatePickerBase.selectedDate = null
    if (label === '学習種別') {
      this.learningTypeDatas = this.learningTypeDatas.map((data: any) => ({ ...data, checked: false }))
    }
    if (label === '教科') {
      this.subjectDatas = this.subjectDatas.map((data: any) => ({ ...data, checked: false }))
    }
    if (label === '学習教材') {
      this.learningMaterialDatas = this.learningMaterialDatas.map((data: any) => ({ ...data, checked: false }))
    }

    this.conditions = this.conditions.filter((condition: { label: string }) => condition.label !== label)
    this.setTimelineCookie()
  }

  private restoreConditions(conditions: Condition[]) {
    this.conditions = conditions
    const start = conditions.find((condition: { label: string }) => condition.label === '期間開始')
    if (start) {
      this.rangeDatePicker.startDatePickerBase.selectedDate = new Date(start.value)
    } else {
      this.rangeDatePicker.startDatePickerBase.selectedDate = null
    }
    const end = conditions.find((condition: { label: string }) => condition.label === '期間終了')
    if (end) {
      this.rangeDatePicker.endDatePickerBase.selectedDate = new Date(end.value)
    }
    const lerningType = conditions.find((condition: { label: string }) => condition.label === '学習種別')
    if (lerningType) {
      this.learningTypeDatas.forEach((data: any) => {
        data.checked = lerningType.value.includes(data.value)
      })
    }
    const subject = conditions.find((condition: { label: string }) => condition.label === '教科')
    if (subject) {
      this.subjectDatas.forEach((data: any) => {
        data.checked = subject.value.includes(data.value)
      })
    }
    const learningMaterial = conditions.find((condition: { label: string }) => condition.label === '学習教材')
    if (learningMaterial) {
      this.learningMaterialDatas.forEach((data: any) => {
        data.checked = learningMaterial.value.includes(data.value)
      })
    }
  }

  @Watch('conditions', { deep: true })
  private conditionsChange() {
    // 条件が設定された時の初回取得
    this.timelineData = []
    this.lastData = {}
    this.loadDatas()
    this.observer.observe(this.observeElement)
  }
}
